import { enUS } from './en-US';

export const esAR: typeof enUS = {
  breadcrumb: {
    commercialAgreements: {
      title: 'Acuerdos comerciales',
    },
    JBPManagement: {
      title: 'JBP Management',
    },
    JBPVisibility: {
      title: 'Visibilidad de los ICR',
      kpiPage: {
        title: 'Detalles de los acuerdos comerciales',
      },
    },
    JBPContractManagment: {
      title: 'Gestión de los contratos',
      createContract: {
        title: 'Create contract',
      },
      createContractUpload: {
        title: 'Creación de contratos',
      },
    },
    JPBUpdateKPIs: {
      title: 'Actualización de los contratos',
    },
    PINVisibility: {
      title: 'Visibilidad de los ICR',
      subTitle: 'Track the KPIs progress by chains and stores.',
      kpiPage: {
        title: 'Detalles de los acuerdos comerciales',
      },
    },
    CatalogPage: {
      title: 'Catalog Page',
    },
    newInsights: {
      title: 'Detalles de los acuerdos comerciales',
    },
  },
  leverBar: {
    targetAchieved: 'Target achieved',
    missingTargetFirst: ' ',
    missingTargetSecond: 'is missing for the target of ',
    potentialPrize: 'Potential payout',
  },
  leverDonut: {
    storesOnTarget: 'Stores on target',
    storesOutTarget: 'Stores out target',
    noData: 'No data',
    targetPerStore: 'Target per store',
    totalStoresTarget: 'Total stores target',
    potentialReward: 'Potential payout',
  },
  errorMessage: {
    400: 'Something went wrong. Please, try again later. If the problem persists, contact the support.',
    403: 'The information you are trying to access is not available.',
    500: 'Something went wrong. Please, try again later. If the problem persists, contact the support.',
    404: {
      buttonText: 'Go back',
      headerText: 'Mmm… Looks like this page is gone',
      subHeaderText: 'It looks like this page wasn’t found or doesn’t exist.',
    },
    503: {
      buttonText: 'Try again',
      headerText: 'Hm... Service unavailable!',
      infoText: 'If the issue persists, you can report the problem.',
      subheaderText:
        'Our servers are temporarily too busy. You can try again in a few minutes, and it should be normalized.',
    },
    leversPage: {
      403: {
        title: 'Hmm... Access denied!',
        subtitle: "It looks like this page wasn't found or doesn't exist.",
        buttonText: 'Home',
      },
      404: {
        title: 'No se encontraron acuerdos',
        subtitle:
          'Esta cadena no tiene ningún acuerdo registrado. Crear un nuevo acuerdo o contrato.',
        buttonText: 'Crear un nuevo contrato',
      },
      503: {
        title: 'Hm... Service unavailable!',
        subtitle:
          'Our servers are temporarily too busy. You can try again in a few minutes, and it should be normalized.',
        buttonText: 'Try again',
        infoText: 'If the issue persists, you can report the problem.',
      },
      otherStatus: '¡Uy! Algo salió mal',
    },
    pinsPage: {
      403: {
        title: 'Hmm... Access denied!',
        subtitle: "It looks like this page wasn't found or doesn't exist.",
        buttonText: 'Home',
      },
      404: {
        title: 'No se encontraron acuerdos',
        subtitle:
          'Esta cadena no tiene ningún acuerdo registrado. Crear un nuevo acuerdo o contrato.',
        buttonText: 'Crear un nuevo contrato',
      },
      503: {
        title: 'Hm... Service unavailable!',
        subtitle:
          'Our servers are temporarily too busy. You can try again in a few minutes, and it should be normalized.',
        buttonText: 'Try again',
        infoText: 'If the issue persists, you can report the problem.',
      },
      otherStatus: '¡Uy! Algo salió mal',
    },
    newInsightsPage: {
      history: {
        404: {
          subtitle: 'No hay contratos anteriores para mostrar. Verificá tu contrato actual.',
        },
      },
      currentContract: {
        404: {
          title: 'No se encontraron acuerdos',
          subtitle: 'Esta cadena no tiene ningún acuerdo registrado.',
          subtitleSecondary: 'Crear un nuevo acuerdo o contrato.',
          subtitleSelectedPeriod:
            "This chain doesn't have any registered agreements for the selected period.",
          firstAction: 'Crear un nuevo acuerdo',
          secondAction: 'Crear un nuevo contrato',
        },
      },
    },
    accessDenied: {
      title: 'Hmm... Access denied!',
      subtitle: "It looks like you don't have permission to access this page.",
      buttonText: 'Home',
    },
  },
  table: {
    rowsPerPage: 'Elementos por página',
    paginationCounter: 'de',
    emptyMessage: 'No hay información para mostrar',
    loadingMessage: 'Cargando datos',
    selectable: {
      displayTextOneRowSelected: 'selected',
      displayTextMoreThanOneRowSelected: 'selected',
      displayTextDeselectAll: 'Deselect all',
    },
  },
  kpiComponent: {
    achievement: 'Achievement: ',
    currentProgress: 'Current progress: ',
    targetAchieved: 'Target achieved',
    overTarget: 'Over target',
    targetKpi: 'Target: ',
    remainingTarget: 'Remaining target:',
    amountOverTarget: 'Amount over target:',
    percentageAchieved: 'Percentage achieved: ',
    store: 'store',
    stores: 'stores',
  },
  rewardCard: {
    title: 'Recompensa ganada',
    label: 'Payouts summary',
    targetProgress: {
      emptyState: 'Comenzá a hacer pedidos para verificar la recompensa.',
      supportInfo: {
        increase: 'ganados el mes pasado',
        decrease: 'ganados el mes pasado',
      },
      allCompleted: 'Alcanzado el máximo',
    },
    totalRevenue: {
      toEarn: 'por ganar',
      moreToEarn: 'more to earn',
    },
  },
  components: {
    growthKpiCard: {
      acceptButton: 'Accept',
      targetReachedMessage: 'Objetivo alcanzado!',
      invoicedOrder: 'Pedidos facturados',
      orderInProgress: 'Order in progress',
      targetLabel: 'Meta',
      currentTarget: 'Meta actual',
      remainingLabel: 'Remaining',
      rewardLabel: 'Recompensa',
      currentRebate: 'Recompensa actual',
      comparisonLabel: 'Hasta',
      finalRewardDescription: 'Alcanzado el máximo',
      lastUpdateLabel: 'Última actualización',
      date: '%day de %month de %year',
      trayButton: 'Mostrar detalles del objetivo',
      orderInProgressAlertMessage:
        'Returned or undelivered products will not be included in the KPI progress.',
      measureTypes: {
        singular: {
          case: 'caja',
        },
        plural: {
          case: 'cajas',
        },
      },
      rewardTooltip: 'Pago basado en el logro de este ICR.',
    },
    numericKpiCard: {
      progress: '%progress of %target',
      targetReachedMessage: 'Target reached!',
      targetLabel: 'Target',
      remainingLabel: 'Remaining',
      rewardLabel: 'Potential payout',
      finalRewardLabel: 'Final payout',
      rewardTooltip: 'Payout based on the achievement for this KPI.',
      rewardTooltipIRS: 'Current payout based on the achieved targets for this KPI.',
      finalRewardTooltip: 'Maximum payout based on the achieved targets for this KPI.',
      lastUpdateLabel: 'Last update',
      measureTypes: {
        singular: {
          store: 'store',
        },
        plural: {
          store: 'stores',
        },
      },
    },
    percentageKpiCard: {
      progress: '%progress of %target',
      targetReachedMessage: 'Objetivo alcanzado',
      targetLabel: 'Meta actual',
      remainingLabel: 'Recompensa actual',
      rewardLabel: 'Recompensa',
      finalRewardLabel: 'Recompensa final',
      maximumAchieved: 'Alcanzado el máximo',
      comparisonLabel: 'Hasta',
      rewardTooltip: 'Pago basado en el logro de este ICR.',
      rewardTooltipIRS: 'Current payout based on the achieved targets for this KPI.',
      currentRewardTooltip: 'Current payout based on the achieved targets for this ICR.',
      finalRewardTooltip: 'Pago máximo basado en los objetivos alcanzados para este ICR.',
      lastUpdateLabel: 'Última actualización',
      bulkText: 'El empaquetado en masa ayuda en este objetivo.',
      convenienceText: 'El empaquetado desechable ayuda en este objetivo.',
    },
    irsKpiCard: {
      targetReachedMessage: 'Exit reached!',
      errorMessage: 'Something went wrong.',
      notStartedMessage: 'An order of %value cases will increase this KPI by 10%.',
      inProgressMessage: '%value from last week',
      targetAchievedMessage: 'Your client must continue buying products to increase the payout!',
      exitAchievedMessage: 'Exit achieved! Maximum guaranteed payout.',
      invoicedOrder: 'Pedidos facturados',
      targetLabel: 'Target',
      exitLabel: 'Exit',
      rewardLabel: 'Potential payout',
      comparisonRewardLabel: 'Up to',
      finalRewardLabel: 'Final payout',
      finalRewardDescription: 'Maximum guaranteed payout',
      rewardTooltip: 'Payout based on the achieved targets for this KPI. Incl. VAT.',
      exitTooltip: 'Maximum cases amount to receive a payout.',
      targetTooltip: 'Minimum cases amount to start receiving a payout.',
      finalRewardTooltip: 'Maximum payout based on the achieved targets for this KPI. Incl. VAT.',
      lastUpdateLabel: 'Last update',
      lastUpdateDate: '%month %day, %year',
      measureTypes: {
        singular: {
          case: 'case',
        },
        plural: {
          case: 'cases',
        },
      },
    },
    generalKpiCardInfo: {
      lastUpdateDate: '%day de %month, %year',
      statusLabel: {
        notStarted: 'No iniciado',
        waitingAcceptance: 'Waiting acceptance',
        accepted: 'Accepted',
        inProgress: 'En progreso',
        achieved: 'Logrado',
        notAchieved: 'No se logró',
        expired: 'Vencido',
        almostAchieved: 'Partially achieved',
        overAchieved: 'Exceeded ',
        exceeded: 'Exceeded ',
      },
    },
    historyTable: {
      contract: 'Contrato',
      contractId: 'No. de identificación del contrato',
      targetsAchieved: 'Metas alcanzadas',
      targetsAchievedMessage: '%s1 de %s2 ICR',
      performanceMessage: '% del último contrato',
      rewardEarned: 'Recompensa ganada',
      rewardEarnedMessage: 'de ',
      date: 'Válido desde: el %s1 de %s2 de %s3 hasta el %s4 de %s5 de %s6',
      showDetails: 'Mostrar detalles',
      hideDetails: 'Ocultar detalles',
      tableTitle: 'Detalles del ICR',
      columns: {
        kpi: 'ICR',
        status: 'Estado',
        target: 'Objetivo alcanzado',
        remaining: 'Restante',
        rebate: 'Recompensa',
        reward: 'Recompensa ganada',
        type: 'Tipo',
      },
      status: {
        achieved: 'Logrado',
        notAchieved: 'No se logró',
        expired: 'Vencido',
      },
      table: {
        label: 'Past contract KPI',
        messages1:
          'Your client will receive a share of the payout for almost achieving 1 or more goals.',
        messages2: 'Your client will receive a bonus payout for overachieving 1 or more goals.',
        messages3:
          'Your client payouts have been adjusted as it have almost reached and overachieved 1 or more goals.',
      },
      unitsMeasurement: {
        store: 'store',
        stores: 'stores',
        percentage: '%',
        hectoliter: 'hL',
        case: 'Cs',
      },
    },
    abbreviatedMonthNames: {
      m01: 'Ene',
      m02: 'Feb',
      m03: 'Mar',
      m04: 'Abr',
      m05: 'May',
      m06: 'Jun',
      m07: 'Jul',
      m08: 'Ago',
      m09: 'Sep',
      m10: 'Oct',
      m11: 'Nov',
      m12: 'Dic',
    },
    selectWithSearch: {
      searchPlaceholder: 'Search by stores',
      showAllStores: 'Show all stores',
      storeName: '%storeName - %storeCode',
      allStores: 'All stores',
      noResults: 'No results. Review your search or try searching for a different store.',
    },
    tray: {
      currentTarget: 'Meta actual',
      currentRebate: 'Recompensa actual',
      targetLevels: 'Niveles de metas',
      textButton: 'Mostrar detalles del objetivo',
      tableHeader: {
        indicator: 'Nombre',
        target: 'Objetivo',
        targetRebate: 'Recompensa de la meta',
        status: 'Status',
      },
      statusLabel: {
        success: 'Éxito',
        inProgress: 'En progresso',
        notStarted: 'No iniciado',
        default: ' ',
      },
    },
  },
  kpiCard: {
    title: 'ICR en el contrato',
    amountToAchieve: 'por lograr',
    acceptTargets: 'Comenzá a hacer pedidos para verificar el progreso.',
    achievedLastMonth: 'ganados el mes pasado',
    allCompleted: '¡Se alcanzaron todas las metas!',
    name: 'ICR',
  },
  pages: {
    home: {
      title: 'Acuerdos comerciales',
      subTitle: 'Gestiona y haga seguimiento de los contratos comerciales de los clientes.',
      navigationCards: {
        JBPManagement: {
          title: 'Commercial agreements management',
          subTitle: 'Define KPIs and commercial agreements',
        },
        JBPVisibility: {
          title: 'Visibilidad de los ICR',
          subTitle:
            'Hacer un seguimiento del progreso de los ICR de los acuerdos comerciales por cadena y PDV',
        },
        JBPContractManagment: {
          title: 'Gestión de los contratos',
          subTitle: 'Creá y gestioná los contratos de tus clientes.',
        },
        JBPUpdateKPIs: {
          title: 'Actualización de los contratos',
          subTitle:
            'Actualice en masa los objetivos o el progreso de las cadenas y las tiendas a la vez.',
        },
        CreateKPIsByUpload: {
          title: 'Contract Creation',
          subTitle: 'Bulk create contracts for multiple chains and stores at once.',
        },
      },
      informationMessage: {
        text: 'BEESHUB team is working hard to help the day-by-day on trade executions, new features are',
        highlight: 'coming soon..',
      },
    },
    JBPVisibility: {
      title: 'Visibilidad de los ICR',
      subTitle: 'Realizar un seguimiento del progreso de los ICR por cadena y PDV.',
      chainTable: {
        idNumber: 'No. de identificación del contrato',
        chainName: 'Nombre del cliente',
        type: 'Tipo',
        searchPlaceholder: 'Buscar por nombre o número de identificación del cliente',
        emptyMessage: 'Chain not found',
        loadingMessage: 'Loading clients...',
        group: 'Cadena',
        store: 'Tienda',
        independentStore: 'Independent store',
      },
      kpiPage: {
        title: 'Detalles de los acuerdos comerciales',
        currentKpis: 'current KPIs',
        kpiSection: {
          growth: {
            title: 'Growth KPIs',
            subtitle: 'Get an overview of your general progress on growth driving.',
          },
          categoryGrowth: {
            title: 'Category growth KPIs',
            subtitle: 'Get an overview of the progress in each category.',
          },
          trade: {
            title: 'Trade KPIs',
            subtitle: 'Get an overview of the general progress on trade driving.',
          },
        },
      },
    },
    JBPContractManagment: {
      title: 'Gestión de los contratos',
      subTitle: 'Creá y gestioná los contratos de tus clientes.',
      updateButton: 'Actualizar contratos',
      createNewContract: 'Crear contrato',
      contractDetails: 'Contract details',
      table: {
        searchPlaceholder: 'Buscar por ID de contrato o por cliente',
        client: 'Cliente',
        contractId: 'ID de contrato',
        contractType: 'Tipo de contrato',
        status: {
          label: 'Estado',
          active: 'Activo',
          canceled: 'Cancelado',
          expired: 'Expirado',
        },
        startDate: 'Fecha de inicio',
        endDate: 'Fecha de finalización',
        lastUpdate: 'Última actualización',
        date: '%day de %month de %year',
        actions: 'Acciones',
        manageDrivers: 'Gestionar metas',
        cancelContract: 'Cancelar contratos',
      },
      create: {
        requiredField: 'Field required.',
        requiredValidNumber: 'Field required. Insert a valid number.',
        endEarlierStartDate: 'End date is earlier than start date',
        detailsStep: {
          label: 'Contract details',
          detail: 'Enter the chain information',
          selectChain: 'Select the chain',
          select: 'Select',
          contractId: 'Contract ID',
          insert: 'Insert',
          startDate: 'Start date of the contract',
          endDate: 'End date of the contract',
          datePlaceholder: 'MM/DD/YYYY',
          cancel: 'Cancel',
          continue: 'Continue',
        },
        driversStep: {
          label: 'Contract KPIs',
          detail: 'Define the contract targets.',
          driverName: 'KPI name',
          kpiCategory: 'KPI category',
          kpiCategorySub:
            'Only one KPI can be active per chain. All active contracts are considered.',
          select: 'Select',
          measurement: 'Measurement',
          target: 'Target',
          reward: 'Payout',
          optional: 'optional',
          rewardPlaceholder: 'Insert payout',
          pageSection: 'Page section',
          enterName: 'Enter name',
          enterTarget: 'Insert target',
          addNew: 'Add new',
          addExisting: 'Add existing',
          addNewTitle: 'Create a new contract',
          addNewSubTitle: 'Create a new KPI to add to your contract.',
          editSubtitle: 'Edit the KPI information',
          addAdditional: 'Add new KPI',
          saveAndContinue: 'Finish',
          saveChanges: 'Save changes',
          successfullyCreated: 'KPI successfully created!',
          successfullyUpdated: 'KPI successfully updated!',
          alreadyInUse: 'This KPI category is already in use.',
          cardTitle: 'KPIs',
          cardDetail: 'Define contract targets and track progress.',
          cannotEditTargets: 'Once a contract has been created, you can only edit its progress.',
          addTargetsWillBeShown: 'The add levers will be shown here.',
          goBackButton: 'Go back',
          addContractButton: 'Add to contract',
          editDriver: 'Edit KPI',
          deleteDriver: 'Remove KPI',
          completed: 'Completed',
          notCompleted: 'Not completed',
          hectoliterSymbol: 'Hl',
          contractSuccessfullyCreated: 'Contract successfully created!',
          saveContractError: 'Something went wrong!',
          savePermissionError: "You don't have permission to perform this action.",
          sameContractId: 'This ID is already in use.',
          kpiSuccessfullyRemoved: 'KPI successfully removed!',
          modalDeleteDriver: {
            title: 'Remove KPI?',
            confirmButton: 'Yes, delete',
            cancelButton: 'No, go back',
          },
          notEnabled: 'Already in a contract',
          modalDriverAvailable: {
            title: 'Existing KPIs',
            closeButton: 'Check KPIs',
            message:
              "There's 1 or more KPI active in a current contract. Check the KPIs to create the contract.",
          },
        },
      },
      details: {
        title: 'Contract details',
        startDate: 'Start date',
        endDate: 'End date',
        cancel: 'Cancel',
        addNew: 'Add new',
        target: 'Target',
        reward: 'Payout',
        progress: 'Progress',
        openProgress: 'Open progress',
        deactivateDriver: 'Deactivate KPI',
        showTargetProgress: {
          title: 'Show target progress',
          description: 'Update your targets for each KPI to track the progress of the contract.',
        },
        updateDriver: {
          showProgress: 'Show progress',
          updateProgess: 'Update progress',
          save: 'Save',
          update: 'Update',
          status: 'Driver status:',
          kpiCategory: 'KPI category:',
          driverMeasurement: 'Driver measurement:',
          kpiMeasurement: 'KPI measurement:',
          target: 'Target:',
          progress: 'Progress',
          companyReachItsTarget: 'Did the company reach its target?',
          yes: 'Yes',
          no: 'No',
          driverUpdated: 'KPI sucessfully updated!',
          driverUpdateError: 'Something went wrong!',
          insertValidNumber: 'Insert a valid number.',
          permissionError: "You don't have permission to perform this action.",
        },
        measurement: {
          currency: 'Currency',
          number: 'Number',
          volHectoliter: 'Volume (hL)',
          volCase: 'Volume (Cs)',
          percentage: 'Percentage',
          volume: 'Volume',
          trueFalse: 'Completed/Not completed',
        },
        monthOfUpdate: {
          title: 'Month of update ',
          subtitle: 'Month that will be updated',
        },
      },
      delete: {
        title: '¿Eliminar acuerdos?',
        subTitle: `Estás eliminando %agreements acuerdo(s) del %clients cliente.`,
        detail: 'Esta acción no se puede deshacer.',
        confirmButton: 'Sí, eliminar',
        cancelButton: 'No, volver',
        canceledContract: 'Contrato cancelado con éxito.',
        canceledContractError: "You don't have permission to perform this action.",
        errorCancelMessage: 'Something went wrong.',
      },
      modalCreateContracts: {
        title: 'Create contract',
        subtitle: 'Select how you want to create the contract:',
        options: {
          JBP: {
            title: 'JBP creation',
            subtitle: 'Create a single contract for a retailer group.',
          },
          IRS: {
            title: 'IRS creation',
            subtitle: 'Create multiple contracts for multiple stores at once.',
          },
        },
        continueButton: 'Continue',
      },
    },
    JBPUpdateKPIs: {
      title: 'Actualización de los contratos',
      subTitle: 'Actualice en masa los ICR del progreso de las cadenas y las tiendas a la vez.',
      uploadCard: {
        title: 'Cargar archivo',
        subTitle: 'Utilice la plantilla para completar la información del contrato.',
      },
      downloadSpreadsheet: {
        title: 'Descargar plantilla',
        spreadsheetJBP: 'Plantilla de PNC',
        spreadsheetIRS: 'Plantilla de ERI',
      },
      fileUploader: {
        message: 'El archivo debe tener el formato XLS o XLSX y un tamaño de hasta 1 MB.',
        button: 'Arrastra tu archivo hasta acá o buscalo',
        uploadSuccess: 'File successfully uploaded!',
        uploadError: {
          tryAgain: 'Cargar un nuevo archivo',
          length: 'Selecciona un archivo a la vez.',
          size: 'El archivo superó el límite de tamaño. Revisa el archivo y inténtalo de nuevo.',
          format: 'No se admite el formato del archivo. Revisa el archivo y inténtalo de nuevo.',
          failed: 'El archivo no se cargó correctamente. Intentalo de nuevo.',
          messageServer: 'File upload failed. Try again or contact support.',
        },
      },
      fileSummary: {
        analysis: 'Análisis de los archivos',
        fileBeingAnalyzed: 'Estamos analizando tus archivos.',
        dontCloseWindow: 'No cierres esta ventana.',
        fileSummary: 'Análisis de los archivos',
        chains: 'Chains',
        clients: 'Clientes',
        contracts: 'Contratos',
        kpis: 'ICR',
        inconsistencies: 'Inconsistencias',
        alert:
          'Descargue el archivo analizado para revisar las inconsistencias y corregirlas. Después cargalo de nuevo.',
        fileProcessingError:
          'No se puede analizar el archivo. Actualizá esta página y cargá el archivo otra vez.',
        reProcessFile: 'Intentar de nuevo',
      },
      downloadSummarySpreadsheet: 'Descargar archivo analizado',
      doneButton: 'Actualizar',
    },
    PINVisibility: {
      title: 'Visibilidad de los ICR',
      subTitle: 'Realizar un seguimiento del progreso de los ICR por cadena y PDV.',
      updateKpis: 'Actualizar ICR',
      chainTable: {
        idNumber: 'ID Number',
        chainName: 'Chain name',
        searchPlaceholder: 'Buscar por nombre o número de identificación del cliente',
        emptyMessage: 'Chain not found',
        loadingMessage: 'Loading clients...',
      },
      kpiPage: {
        title: 'Detalles de los acuerdos comerciales',
        currentKpis: 'current KPIs',
        pinLevers: {
          title: 'Invoice levers KPIs',
          subtitle: 'Get an overview of the progress of your KPIs.',
          onLeverClicked: 'Lever accepted! Follow up lever to get the payout.',
          cards: {
            loading: 'Loading levers',
            loadingAux: "We're almost there...",
            incompleteTargetText: '%s1 is missing for the target of %s2',
            completeTargetText: 'Target achieved, %s',
            potentialReward: 'Potential payout',
            toBeAccepted: 'To be accepted',
            accepted: 'Accepted',
            tooltipText: 'This lever includes the following items:',
          },
          homeButton: 'Home',
          tryAgainButton: 'Try again',
          errors: {
            tryAgainText: 'Try again',
            homeText: 'Home',
            '403': {
              title: 'Humm... Access denied!',
              subtitle: "It looks like this page wasn't found or doesn't exist.",
            },
            '404': {
              title: 'Mmm... Looks like this page is gone.',
              subtitle: "It looks like this page wasn't found or doesn't exist.",
            },
            '503': {
              title: 'Hm... Service unavailable!',
              subtitle:
                'Our servers are temporarily too busy. You can try again in a few minutes, and it should be normalized.',
              auxText: 'If the issue persists, you can report the problem.',
            },
            default: {
              title: 'Hm... Service unavailable!',
            },
          },
        },
      },
    },
    newInsightsPage: {
      title: 'Detalles de los acuerdos comerciales',
      subTitle: 'Realice un seguimiento de los ICR de la cadena que gestionas.',
      contractSummary: {
        title: 'Resumen del contrato',
        activeOneContractsLabel: 'contrato activo',
        activeMultiContractsLabel: 'contratos activos',
        showContracts: 'Mostrar contrato',
      },
      activeContracts: {
        title: 'Contratos activos',
        subtitle: 'Accedé a los detalles de los contratos y a las tiendas participantes.',
        idLabel: 'ID',
        typeLabel: 'Tipo',
        date: 'Válido del {startDay} de {startMonth} de {startYear} al {endDay} de {endMonth} de {endYear}',
      },
      history: {
        details: {
          kpi: 'ICR',
          status: 'Estado',
          target: 'Objetivo alcanzado',
          remaining: 'Restante',
          reward: 'Recompensa ganada',
          rebate: 'Recompensa',
          notAchieved: 'No se logró',
          achieved: 'Logrado',
          expired: 'Vencido',
          almostAchieved: 'Parcialmente logrado',
          overAchieved: 'Excedido ',
          partiallyAchieved: 'Parcialmente logrado',
          type: 'Tipo',
        },
        error: 'Unable to load contract details. Reload this page and try again.',
      },
      currentContract: {
        generalGroup: {
          title: 'General KPIs',
          description: ' ',
        },
        totalGrowth: {
          title: 'Total growth KPI',
          description: 'All orders made by your chain.',
        },
        categoryGrowth: {
          title: 'Category growth KPIs',
          description: 'Orders on specific categories made by your chain.',
        },
        shareSpace: {
          title: 'Share space KPIs',
          description: 'Products displayed in shelf space of point of sale.',
        },
        irsKpi: {
          title: 'IRS KPI',
          description: 'All bulk products ordered by your chain.',
        },
        metasMix: {
          title: 'Escalas de MIX',
          description: 'Progreso en categorías específicas hechas por tu grupo.',
        },
        metasVolumen: {
          title: 'Escalas de volumen',
          description: 'Progreso en categorías específicas hechas por tu grupo.',
        },
        metasDePerfomance: {
          title: 'Escalas de performance',
          description: 'Progreso en categorías específicas hechas por tu grupo.',
        },
        totalVolume: {
          title: 'Total volume',
          description: ' ',
        },
        convenienceVolume: {
          title: 'Convenience volume',
          description: ' ',
        },
        totalGrowthTitle: 'Total growth KPI',
        totalGrowthDescription: 'All orders made by your chain.',
        categoryGrowthTitle: 'Category growth KPIs',
        categoryGrowthDescription: 'Orders on specific categories made by your chain.',
        shareSpaceTitle: 'Share space KPIs',
        shareSpaceDescription: 'Products displayed in shelf space of point of sale.',
      },
      currentContractTab: 'Contrato actual',
      historyTab: 'Contratos anteriores',
    },
    CreateKPIsByUpload: {
      title: 'Creación de contratos',
      subTitle: 'Creá contratos en masa para varias cadenas y tiendas a la vez.',
      uploadCard: {
        title: 'Upload a file',
        subTitle: 'Use the spreadsheet template to fill the update information.',
      },
      downloadSpreadsheet: 'Descargar plantilla de hoja de cálculo',
      doneButton: 'Crear',
    },
  },
};
